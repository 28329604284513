import Link from 'next/link';
import Layout from "../components/3_organism/layout/layout";
import Styles from './extras/styles/index.module.scss'
import Team from '../public/img/icon/indexFeatures/team.svg';
import Signup from '../public/img/icon/indexFeatures/signup.svg';
import Filter from '../public/img/icon/indexFeatures/filter.svg';
import MultiCharSignup from '../public/img/icon/indexFeatures/multiCharSignup.svg';
import Navigation from '../public/img/icon/indexFeatures/navigation.svg';
import SmartLists from '../public/img/icon/indexFeatures/smartLists.svg';
import Template from '../public/img/icon/indexFeatures/template.svg';
import Live from '../public/img/icon/indexFeatures/live.svg';
import Robot from '../public/img/icon/indexFeatures/robot.svg';
import SplitSignups from '../public/img/icon/indexFeatures/splitSignups.svg';
import Collaboration from '../public/img/icon/indexFeatures/collaboration.svg';
import Discord from '../public/img/discordIcon.svg';
import Button from '../components/1_atom/buttons/button';
import Tag from '../components/1_atom/tag/tag';
import DiscordServerButton from '../components/1_atom/buttons/discordServerButton';
import CtaWithScreenshot from '../components/2_molecule/cta/ctaWithScreenshot';

export default function Home() {

  const GetStarted = () => {
    return (
      <Link href="/signups">
        <Button
          size="small"
          text="Get started"
          color="white-on-dark"
          variant="soft"
          addClass="clickable"
        />
      </Link>
    )
  }

  const hero = (
    <div className={`${Styles.container} col pb3 align-center gradient-box`}>
      <h1 className="text-center mb1">Signups + Assignments</h1>
      <h3 className="text-center mb2">Together at last.</h3>
      {/* <h5 className="mb3 text-center tablet-only">Taking the hassle out of assignments <br className='mobile-only' />for both leaders and members.</h5> */}
      <GetStarted />
    </div>
  );

  const info = (
    <div className={`${Styles.infoContainer}`}>
      <div className={`${Styles.infoThird}`}>
        <Collaboration />
        <div className="flex-row wrap" style={{ justifyContent: "center" }}>
          <a href="/documentation#real-time-and-collaboration">
            <h3>Collaborative</h3>
          </a>
        </div>
        <p>Promote admins and edit assignments in realtime with each other.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <Live />
        <a href="/documentation#real-time-and-collaboration">
          <h3>Live updates</h3>
        </a>
        <p>Saving changes publishes to all members instantly. No refresh needed.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <Discord />
        <a href="/bot">
          <h3>Discord bot</h3>
        </a>
        <p>Post signup embeds directly to Discord. New members sync automatically to Prepi.</p>
      </div>
      <div className={`${Styles.infoThird} `}>
        <Team />
        <a href="/documentation#teams">
          <h3>Teams</h3>
        </a>
        <p>Easily share customizable access to your team members.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <MultiCharSignup />
        <a href="/documentation#multi-character-signups">
          <h3>Multi-char signup</h3>
        </a>
        <p>Flexible on what you can bring? Sign up with multiple characters!</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <SplitSignups />
        <div className="pos-rel">
          <h3>Split signups</h3>
          <Tag
            color="secondary"
            text="new"
            addClass={"new-tag-position"}
          />
        </div>
        <p>Having more people than you can fit is now a breeze.</p>
      </div>
      <div className={`${Styles.infoThird} ${Styles.strokeSVG}`}>
        <Robot />
          <a href="/documentation#automations">
          <h3>Automations</h3>
        </a>
        <p>Hate repeating yourself? So do we! Setup an automation and it'll take care of it.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <SmartLists />
        <div className="pos-rel">
          <a href="/documentation#smart-lists">
            <h3>Smart Lists</h3>
          </a>
          <Tag
            color="secondary"
            text="new"
            addClass={"new-tag-position"}
          />
        </div>
        <p>Automatically spread your members into assignments using conditional logic.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <Signup />
        <a href="/documentation#adding-placeholders">
          <h3>Signup.. or not</h3>
        </a>
        <p>Works with or without signups with placeholders. Replacing them later is easy.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <Filter />
        <a href="/documentation#only-my-assignments">
          <h3>Per member filtering</h3>
        </a>
        <p>Show only the stuff that matter to you by clicking "only my assignments".</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <Template />
        <a href="/documentation#templates">
          <h3>Templates</h3>
        </a>
        <p>Start fresh, use pre-made templates or create your own templates.</p>
      </div>
      <div className={`${Styles.infoThird}`}>
        <Navigation />
        <a href="/documentation#specific-assignment-sections">
          <h3>Powerful navigation</h3>
        </a>
        <p>Make assignment navigation really simple or really detailed. It's up to you.</p>
      </div>
    </div>
  )

  const infoThree = (
    <div className={Styles.beginningContainer}>
      <h3>..and so much more!</h3>
    </div>
  )

  const CTA = () => {
    return (
      <div className={`${Styles.ctaContainer} gap2 align-center`}>
        <h3 className='text-center'>Join our community Discord!</h3>
        <p className="text-center text-md leading-6">
          If you have questions, feature requests, want to report a bug or just want to chat, <br />
          the best place to reach out is our community Discord server.
        </p>
        <DiscordServerButton usePrimary/>
      </div>
    )
  }

  return (
    <Layout>
      {hero}
      {info}
      {infoThree}
      <CtaWithScreenshot
        title="Assignments that adapts to your roster"
        text="Effortlessly spread your members into assignments using conditional logic with smart lists."
        getImgSrc={(theme) => `/img/features/smart-lists/smart-lists-${theme}.jpg`}
      />
      <CTA />
    </Layout>
  )
}

import Button from "./button";
import { ButtonProps } from './button'

const DiscordServerButton = ({ subtle = false, usePrimary = false }) => {

  const btnProps: ButtonProps = {
    text: "Join Prepi Discord server",
    addClass: "clickable mb1",
    iconLeft: {
      icon: "discord",
      imgURL: `/img/discordIconPurple.png`
    },
  }

  if(!usePrimary){
    btnProps.colorHex = "#5865F2",
    btnProps.colorHexHover = "#7984F5"
  }

  const Core = () => {
    if (usePrimary)
      return (
        <Button
          {...btnProps}
          iconLeft={{
            icon: "discord",
            imgURL: `/img/discordIconWhite.svg`
          }}
        />
      )
    if(subtle) return <Button {...btnProps} variant="transparent" />
    return <Button {...btnProps} variant="outline" />
  }

  return (
    <a href="https://discord.gg/zGCmJtbHJz" target="_blank" >
      <Core />
    </a>
  );
}

export default DiscordServerButton;
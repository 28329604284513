import Link from "next/link";
import Button from "../../1_atom/buttons/button";
import { useSetAtom } from "jotai";
import { setBannersShownAtom } from "../../../global/state/global";
import { useTheme } from "../../../global/hooks";
import { classNames } from "functions";

interface Props {
  title: string
  text: string
  getImgSrc: (theme: string) => string
  dismissable? : boolean
}

const CtaWithScreenshot = ({ getImgSrc, text, title, dismissable = false }: Props) => {

  const setShownBanner = useSetAtom(setBannersShownAtom)
  const theme = useTheme()
  const imgSrc = getImgSrc(theme)

  const dismissCallback = () => {
    if(dismissable) setShownBanner("SMART-LISTS-INFO")
  }

  const glow = (
    <svg
      viewBox="0 0 1024 1024"
      className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
      aria-hidden="true"
    >
      <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
      <defs>
        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
          <stop stopColor="#7775D6" />
          <stop offset={1} stopColor="#E935C1" />
        </radialGradient>
      </defs>
    </svg>
  )

  const leftText = (
    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
      <h2 
        className={classNames(
          theme === "dark" ? "text-white" : "text-dark"
        )}
       style={{ whiteSpace: "pre-wrap" }}>{title}</h2>
      <p className="mt-6 text-lg leading-8 weak-text" style={{ whiteSpace: "pre-wrap" }}>
        {text}
      </p>
      <div className="mt-10 flex items-center justify-center gap2 lg:justify-start">
        <Link href={"https://youtu.be/Zp4iwd8SGlw"} target="_blank">

          <Button
            text="Watch video"
            iconLeft={{ icon: "video-player" }}
            click={dismissCallback}
          />

        </Link>
        {dismissable && <Button
          text="Maybe later"
          variant="transparent"
          color={theme === "dark" ? "white" : "primary"}
          click={dismissCallback}
        />}
      </div>
    </div>
  )

  const imgRight = (
    <div className="relative mt-16 h-80 lg:mt-8">
      <img
        className="absolute left-0 top-0 w-[57rem] max-w-none rounded bg-white/5 ring-1 ring-white/10"
        src={imgSrc}
        alt="App screenshot"
        width={1824}
        height={1080}
      />
    </div>
  )

  const dismiss = (
    <div className="absolute" style={{
      top: 10,
      right: 10
    }}>
      <Button
        variant="transparent"
        color={theme === "dark" ? "white" : "primary"}
        size="small-sq"
        iconRight={{ icon: "cross-two", fill: true }}
        click={dismissCallback}
      />
    </div>
  )

  return (
    <div className={classNames(
      "mt3 relative rounded isolate overflow-hidden px-6 pt-16 drop-shadow sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0",
      theme === "dark" ? "bg-gray-900" : "bg-gray-100"
    )}>
      {glow}
      {leftText}
      {imgRight}
      {dismissable && dismiss}
    </div>
  )
}

export default CtaWithScreenshot;
import { CSSProperties, FC, MouseEventHandler } from 'react';
import SvgIcon from '../buttons/svgIcon';
import Styles from './tag.module.scss'

interface TagProps {
  text: string
  color?: "primary" | "secondary" | "tertiary" | "warning" | "info" | "error" | "success" | "yellow" | "whiteOnDark" | "white"
  colorHex?: string
  size?: "xs" | "sm" | "md" | "lg"
  style?: CSSProperties
  closable?: boolean
  onClick?: MouseEventHandler<HTMLSpanElement>
  addClass?: string
}

const Tag = ({ text, colorHex, addClass, color = "primary", size = "sm", style, closable, onClick }: TagProps) => {

  const getStandardColor = () => {
    switch (color) {
      case "primary": return "var(--primary)"
      case "whiteOnDark": return "var(--white-on-dark)"
      case "secondary": return "var(--secondary)"
      case "tertiary": return "var(--tertiary)"
      case "info": return "var(--info)"
      case "warning": return "var(--warning)"
      case "success": return "var(--success)"
      case "error": return "var(--error)"
      case "yellow": return "var(--yellow)"
      case "white": return "white"
      default: break;
    }
    return "var(--primary)"
  }

  const btnStyle: CSSProperties & { "--span-color"?: string } = {}
  if(color) btnStyle["--span-color"] = getStandardColor()
  if(colorHex) btnStyle["--span-color"] = colorHex

  return (
    <span
      onClick={onClick}
      className={`${Styles.tag} ${Styles[size]} ${!!onClick ? "clickable" : ""} ${!!addClass ? addClass : ""}`}
      style={{ ...btnStyle, ...style }}
    >
      {text}
      {!!closable ? <SvgIcon icon='cross-two' height={10} width={10} addClass="ml0" color="currentColor" /> : <></>}
    </span>
  )
}

export default Tag;